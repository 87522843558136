@font-face {
  font-family: 'tripsapp-regular';
  src:  url('../fonts/tripsapp-regular/tripsapp-regular.eot?qxwkep');
  src:  url('../fonts/tripsapp-regular/tripsapp-regular.eot?qxwkep#iefix') format('embedded-opentype'),
    url('../fonts/tripsapp-regular/tripsapp-regular.ttf?qxwkep') format('truetype'),
    url('../fonts/tripsapp-regular/tripsapp-regular.woff?qxwkep') format('woff'),
    url('../fonts/tripsapp-regular/tripsapp-regular.svg?qxwkep#tripsapp-regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ta-icon"], [class*=" ta-icon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tripsapp-regular' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ta-icon-agent:before {
  content: "\e900";
}
.ta-icon-airplane_a:before {
  content: "\e901";
}
.ta-icon-airplane_b:before {
  content: "\e902";
}
.ta-icon-anchor:before {
  content: "\e903";
}
.ta-icon-arrow_down_a:before {
  content: "\e904";
}
.ta-icon-arrow_down_b:before {
  content: "\e905";
}
.ta-icon-arrow_left_a:before {
  content: "\e906";
}
.ta-icon-arrow_left_b:before {
  content: "\e907";
}
.ta-icon-arrow_right_a:before {
  content: "\e908";
}
.ta-icon-arrow_right_b:before {
  content: "\e909";
}
.ta-icon-arrow_up_a:before {
  content: "\e90a";
}
.ta-icon-arrow_up_b:before {
  content: "\e90b";
}
.ta-icon-attention:before {
  content: "\e90c";
}
.ta-icon-beach:before {
  content: "\e90d";
}
.ta-icon-bell:before {
  content: "\e90e";
}
.ta-icon-bicycle:before {
  content: "\e90f";
}
.ta-icon-bill:before {
  content: "\e910";
}
.ta-icon-binoculars:before {
  content: "\e911";
}
.ta-icon-boat_a:before {
  content: "\e912";
}
.ta-icon-boat_b:before {
  content: "\e913";
}
.ta-icon-book:before {
  content: "\e914";
}
.ta-icon-building:before {
  content: "\e915";
}
.ta-icon-calendar:before {
  content: "\e916";
}
.ta-icon-camera:before {
  content: "\e917";
}
.ta-icon-car:before {
  content: "\e918";
}
.ta-icon-chat:before {
  content: "\e919";
}
.ta-icon-check:before {
  content: "\e91a";
}
.ta-icon-close:before {
  content: "\e91b";
}
.ta-icon-cocktail:before {
  content: "\e91c";
}
.ta-icon-coffee:before {
  content: "\e91d";
}
.ta-icon-compass:before {
  content: "\e91e";
}
.ta-icon-contacts:before {
  content: "\e91f";
}
.ta-icon-copy:before {
  content: "\e920";
}
.ta-icon-dashboard:before {
  content: "\e921";
}
.ta-icon-diagonal_down_left:before {
  content: "\e922";
}
.ta-icon-diagonal_down_right:before {
  content: "\e923";
}
.ta-icon-diagonal_up_left:before {
  content: "\e924";
}
.ta-icon-diagonal_up_right:before {
  content: "\e925";
}
.ta-icon-dislike:before {
  content: "\e926";
}
.ta-icon-duplicate:before {
  content: "\e927";
}
.ta-icon-edit:before {
  content: "\e928";
}
.ta-icon-expand:before {
  content: "\e929";
}
.ta-icon-film:before {
  content: "\e92a";
}
.ta-icon-flag:before {
  content: "\e92b";
}
.ta-icon-food:before {
  content: "\e92c";
}
.ta-icon-glasses:before {
  content: "\e92d";
}
.ta-icon-globe:before {
  content: "\e92e";
}
.ta-icon-group:before {
  content: "\e92f";
}
.ta-icon-hashtag:before {
  content: "\e930";
}
.ta-icon-heart:before {
  content: "\e931";
}
.ta-icon-help:before {
  content: "\e932";
}
.ta-icon-history:before {
  content: "\e933";
}
.ta-icon-home:before {
  content: "\e934";
}
.ta-icon-hotel:before {
  content: "\e935";
}
.ta-icon-laptop:before {
  content: "\e936";
}
.ta-icon-lighthouse:before {
  content: "\e937";
}
.ta-icon-like:before {
  content: "\e938";
}
.ta-icon-location:before {
  content: "\e939";
}
.ta-icon-locked:before {
  content: "\e93a";
}
.ta-icon-mail:before {
  content: "\e93b";
}
.ta-icon-map_a:before {
  content: "\e93c";
}
.ta-icon-map_b:before {
  content: "\e93d";
}
.ta-icon-minus:before {
  content: "\e93e";
}
.ta-icon-moon:before {
  content: "\e93f";
}
.ta-icon-mountains:before {
  content: "\e940";
}
.ta-icon-musuem:before {
  content: "\e941";
}
.ta-icon-nav_a:before {
  content: "\e942";
}
.ta-icon-nav_b:before {
  content: "\e943";
}
.ta-icon-offlink:before {
  content: "\e944";
}
.ta-icon-palms:before {
  content: "\e945";
}
.ta-icon-passport:before {
  content: "\e946";
}
.ta-icon-payment:before {
  content: "\e947";
}
.ta-icon-phone:before {
  content: "\e948";
}
.ta-icon-photos:before {
  content: "\e949";
}
.ta-icon-plus:before {
  content: "\e94a";
}
.ta-icon-pool:before {
  content: "\e94b";
}
.ta-icon-profile:before {
  content: "\e94c";
}
.ta-icon-reorder:before {
  content: "\e94d";
}
.ta-icon-reservation:before {
  content: "\e94e";
}
.ta-icon-sandals:before {
  content: "\e94f";
}
.ta-icon-search:before {
  content: "\e950";
}
.ta-icon-settings_a:before {
  content: "\e951";
}
.ta-icon-settings_b:before {
  content: "\e952";
}
.ta-icon-share:before {
  content: "\e953";
}
.ta-icon-shopping:before {
  content: "\e954";
}
.ta-icon-signs:before {
  content: "\e955";
}
.ta-icon-spa:before {
  content: "\e956";
}
.ta-icon-sports:before {
  content: "\e957";
}
.ta-icon-star:before {
  content: "\e958";
}
.ta-icon-suitcase_a:before {
  content: "\e959";
}
.ta-icon-suitcase_b:before {
  content: "\e95a";
}
.ta-icon-sun:before {
  content: "\e95b";
}
.ta-icon-tag:before {
  content: "\e95c";
}
.ta-icon-ticket:before {
  content: "\e95d";
}
.ta-icon-train:before {
  content: "\e95e";
}
.ta-icon-trash:before {
  content: "\e95f";
}
.ta-icon-unlocked:before {
  content: "\e960";
}
