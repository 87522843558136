@font-face {
  font-family: 'shs-social';
  src:  url('../fonts/shs-social/shs-social.eot?3do29s');
  src:  url('../fonts/shs-social/shs-social.eot?3do29s#iefix') format('embedded-opentype'),
    url('../fonts/shs-social/shs-social.woff2?3do29s') format('woff2'),
    url('../fonts/shs-social/shs-social.ttf?3do29s') format('truetype'),
    url('../fonts/shs-social/shs-social.woff?3do29s') format('woff'),
    url('../fonts/shs-social/shs-social.svg?3do29s#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="shs-"], [class*=" shs-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'shs-social' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.shs-rss:before {
  content: "\e901";
}
.shs-snapchat:before {
  content: "\e900";
}
.shs-fb-2:before {
  content: "\e601";
}
.shs-gplus:before {
  content: "\e602";
}
.shs-insta:before {
  content: "\e603";
}
.shs-pin:before {
  content: "\e604";
}
.shs-tw-2:before {
  content: "\e605";
}
.shs-yt:before {
  content: "\e600";
}
.shs-fb:before {
  content: "\e608";
}
.shs-li:before {
  content: "\e609";
}
.shs-tw:before {
  content: "\e60a";
}

