@font-face {
  font-family: 'shs-social';
  src: url("../fonts/shs-social/shs-social.eot?3do29s");
  src: url("../fonts/shs-social/shs-social.eot?3do29s#iefix") format("embedded-opentype"), url("../fonts/shs-social/shs-social.woff2?3do29s") format("woff2"), url("../fonts/shs-social/shs-social.ttf?3do29s") format("truetype"), url("../fonts/shs-social/shs-social.woff?3do29s") format("woff"), url("../fonts/shs-social/shs-social.svg?3do29s#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="shs-"], [class*=" shs-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'shs-social' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.shs-rss:before {
  content: "\e901"; }

.shs-snapchat:before {
  content: "\e900"; }

.shs-fb-2:before {
  content: "\e601"; }

.shs-gplus:before {
  content: "\e602"; }

.shs-insta:before {
  content: "\e603"; }

.shs-pin:before {
  content: "\e604"; }

.shs-tw-2:before {
  content: "\e605"; }

.shs-yt:before {
  content: "\e600"; }

.shs-fb:before {
  content: "\e608"; }

.shs-li:before {
  content: "\e609"; }

.shs-tw:before {
  content: "\e60a"; }

@font-face {
  font-family: 'tripsapp-regular';
  src: url("../fonts/tripsapp-regular/tripsapp-regular.eot?qxwkep");
  src: url("../fonts/tripsapp-regular/tripsapp-regular.eot?qxwkep#iefix") format("embedded-opentype"), url("../fonts/tripsapp-regular/tripsapp-regular.ttf?qxwkep") format("truetype"), url("../fonts/tripsapp-regular/tripsapp-regular.woff?qxwkep") format("woff"), url("../fonts/tripsapp-regular/tripsapp-regular.svg?qxwkep#tripsapp-regular") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="ta-icon"], [class*=" ta-icon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tripsapp-regular' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ta-icon-agent:before {
  content: "\e900"; }

.ta-icon-airplane_a:before {
  content: "\e901"; }

.ta-icon-airplane_b:before {
  content: "\e902"; }

.ta-icon-anchor:before {
  content: "\e903"; }

.ta-icon-arrow_down_a:before {
  content: "\e904"; }

.ta-icon-arrow_down_b:before {
  content: "\e905"; }

.ta-icon-arrow_left_a:before {
  content: "\e906"; }

.ta-icon-arrow_left_b:before {
  content: "\e907"; }

.ta-icon-arrow_right_a:before {
  content: "\e908"; }

.ta-icon-arrow_right_b:before {
  content: "\e909"; }

.ta-icon-arrow_up_a:before {
  content: "\e90a"; }

.ta-icon-arrow_up_b:before {
  content: "\e90b"; }

.ta-icon-attention:before {
  content: "\e90c"; }

.ta-icon-beach:before {
  content: "\e90d"; }

.ta-icon-bell:before {
  content: "\e90e"; }

.ta-icon-bicycle:before {
  content: "\e90f"; }

.ta-icon-bill:before {
  content: "\e910"; }

.ta-icon-binoculars:before {
  content: "\e911"; }

.ta-icon-boat_a:before {
  content: "\e912"; }

.ta-icon-boat_b:before {
  content: "\e913"; }

.ta-icon-book:before {
  content: "\e914"; }

.ta-icon-building:before {
  content: "\e915"; }

.ta-icon-calendar:before {
  content: "\e916"; }

.ta-icon-camera:before {
  content: "\e917"; }

.ta-icon-car:before {
  content: "\e918"; }

.ta-icon-chat:before {
  content: "\e919"; }

.ta-icon-check:before {
  content: "\e91a"; }

.ta-icon-close:before {
  content: "\e91b"; }

.ta-icon-cocktail:before {
  content: "\e91c"; }

.ta-icon-coffee:before {
  content: "\e91d"; }

.ta-icon-compass:before {
  content: "\e91e"; }

.ta-icon-contacts:before {
  content: "\e91f"; }

.ta-icon-copy:before {
  content: "\e920"; }

.ta-icon-dashboard:before {
  content: "\e921"; }

.ta-icon-diagonal_down_left:before {
  content: "\e922"; }

.ta-icon-diagonal_down_right:before {
  content: "\e923"; }

.ta-icon-diagonal_up_left:before {
  content: "\e924"; }

.ta-icon-diagonal_up_right:before {
  content: "\e925"; }

.ta-icon-dislike:before {
  content: "\e926"; }

.ta-icon-duplicate:before {
  content: "\e927"; }

.ta-icon-edit:before {
  content: "\e928"; }

.ta-icon-expand:before {
  content: "\e929"; }

.ta-icon-film:before {
  content: "\e92a"; }

.ta-icon-flag:before {
  content: "\e92b"; }

.ta-icon-food:before {
  content: "\e92c"; }

.ta-icon-glasses:before {
  content: "\e92d"; }

.ta-icon-globe:before {
  content: "\e92e"; }

.ta-icon-group:before {
  content: "\e92f"; }

.ta-icon-hashtag:before {
  content: "\e930"; }

.ta-icon-heart:before {
  content: "\e931"; }

.ta-icon-help:before {
  content: "\e932"; }

.ta-icon-history:before {
  content: "\e933"; }

.ta-icon-home:before {
  content: "\e934"; }

.ta-icon-hotel:before {
  content: "\e935"; }

.ta-icon-laptop:before {
  content: "\e936"; }

.ta-icon-lighthouse:before {
  content: "\e937"; }

.ta-icon-like:before {
  content: "\e938"; }

.ta-icon-location:before {
  content: "\e939"; }

.ta-icon-locked:before {
  content: "\e93a"; }

.ta-icon-mail:before {
  content: "\e93b"; }

.ta-icon-map_a:before {
  content: "\e93c"; }

.ta-icon-map_b:before {
  content: "\e93d"; }

.ta-icon-minus:before {
  content: "\e93e"; }

.ta-icon-moon:before {
  content: "\e93f"; }

.ta-icon-mountains:before {
  content: "\e940"; }

.ta-icon-musuem:before {
  content: "\e941"; }

.ta-icon-nav_a:before {
  content: "\e942"; }

.ta-icon-nav_b:before {
  content: "\e943"; }

.ta-icon-offlink:before {
  content: "\e944"; }

.ta-icon-palms:before {
  content: "\e945"; }

.ta-icon-passport:before {
  content: "\e946"; }

.ta-icon-payment:before {
  content: "\e947"; }

.ta-icon-phone:before {
  content: "\e948"; }

.ta-icon-photos:before {
  content: "\e949"; }

.ta-icon-plus:before {
  content: "\e94a"; }

.ta-icon-pool:before {
  content: "\e94b"; }

.ta-icon-profile:before {
  content: "\e94c"; }

.ta-icon-reorder:before {
  content: "\e94d"; }

.ta-icon-reservation:before {
  content: "\e94e"; }

.ta-icon-sandals:before {
  content: "\e94f"; }

.ta-icon-search:before {
  content: "\e950"; }

.ta-icon-settings_a:before {
  content: "\e951"; }

.ta-icon-settings_b:before {
  content: "\e952"; }

.ta-icon-share:before {
  content: "\e953"; }

.ta-icon-shopping:before {
  content: "\e954"; }

.ta-icon-signs:before {
  content: "\e955"; }

.ta-icon-spa:before {
  content: "\e956"; }

.ta-icon-sports:before {
  content: "\e957"; }

.ta-icon-star:before {
  content: "\e958"; }

.ta-icon-suitcase_a:before {
  content: "\e959"; }

.ta-icon-suitcase_b:before {
  content: "\e95a"; }

.ta-icon-sun:before {
  content: "\e95b"; }

.ta-icon-tag:before {
  content: "\e95c"; }

.ta-icon-ticket:before {
  content: "\e95d"; }

.ta-icon-train:before {
  content: "\e95e"; }

.ta-icon-trash:before {
  content: "\e95f"; }

.ta-icon-unlocked:before {
  content: "\e960"; }

html, body {
  font-size: 10px;
  line-height: 1;
  font-family: 'Lato', sans-serif;
  font-weight: 300; }

/* This may be an accessibility issue, but turning off for now... */
*:focus {
  outline: none !important; }

img {
  max-width: 100%; }

.ltt .navbar-toggler {
  border: none;
  background-image: none;
  text-align: center;
  color: white; }
  .ltt .navbar-toggler span {
    font-size: 2rem;
    line-height: 1.5; }
  .ltt .navbar-toggler .closeBtn {
    display: none;
    font-size: 1.75rem;
    margin-left: -2em; }
  .ltt .navbar-toggler.active .navbar-toggler-icon {
    display: none; }
  .ltt .navbar-toggler.active .closeBtn {
    display: block; }

.ltt .navbar {
  z-index: 200;
  min-height: 5.5rem;
  display: flex;
  justify-content: space-between;
  position: absolute; }
  .ltt .navbar.dark {
    background: #151919; }
  .ltt .navbar .navbar-brand {
    display: flex;
    align-items: center;
    font-size: 2rem;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #e2f3f7; }
    .ltt .navbar .navbar-brand i {
      font-size: 2.5rem;
      margin-right: .5rem; }
  .ltt .navbar .navbar-nav {
    justify-content: end; }
    .ltt .navbar .navbar-nav .nav-link {
      color: #e2f3f7;
      font-size: 1.25rem;
      text-transform: uppercase; }
      .ltt .navbar .navbar-nav .nav-link.active {
        font-weight: 700;
        border-bottom: 2px solid; }
    .ltt .navbar .navbar-nav li:nth-last-child(2) .btn {
      background: none;
      border: none;
      font-weight: 200;
      font-size: 1.6rem;
      line-height: 1.5;
      padding: .5rem;
      color: rgba(0, 0, 0, 0.5) !important; }
      .ltt .navbar .navbar-nav li:nth-last-child(2) .btn:hover {
        color: black !important; }
    .ltt .navbar .navbar-nav .btn {
      color: white; }
  .ltt .navbar .collapse.navbar-collapse {
    flex: 0 0 auto;
    align-content: flex-end; }
    .ltt .navbar .collapse.navbar-collapse .nav-item {
      margin: 0 2rem; }
      .ltt .navbar .collapse.navbar-collapse .nav-item:last-child {
        margin-right: 0; }
    .ltt .navbar .collapse.navbar-collapse .btn {
      padding: 1rem 2rem; }
    .ltt .navbar .collapse.navbar-collapse.show {
      position: absolute;
      z-index: 10010;
      right: 0;
      top: 6.75rem;
      background: white;
      opacity: 1;
      -webkit-transition: all .125s linear;
      transition: all .125s linear;
      padding: 2rem 1rem;
      filter: drop-shadow(-0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.25)); }
      .ltt .navbar .collapse.navbar-collapse.show .nav-link {
        color: black;
        margin-bottom: .5rem;
        text-align: left; }

.ltt .page h1 {
  margin-top: 5rem; }

.ltt .billboard {
  min-height: 45rem;
  padding: 0;
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  color: white;
  position: relative; }
  .ltt .billboard .container {
    position: relative;
    z-index: 100; }
  .ltt .billboard:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#151919+0,151919+100&0+41,1+100 */
    background: -moz-linear-gradient(top, rgba(21, 25, 25, 0) 0%, rgba(21, 25, 25, 0) 41%, #151919 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(21, 25, 25, 0) 0%, rgba(21, 25, 25, 0) 41%, #151919 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(21, 25, 25, 0) 0%, rgba(21, 25, 25, 0) 41%, #151919 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00151919', endColorstr='#151919',GradientType=0 );
    /* IE6-9 */ }
  .ltt .billboard .logo {
    margin: 0 auto;
    display: block;
    width: 100%; }
  .ltt .billboard h1 {
    text-align: center;
    width: 100%;
    font-size: 1.6rem;
    margin-top: 2rem; }

.ltt .home-band {
  padding-top: 7.5em;
  padding-bottom: 4em;
  text-align: center; }
  .ltt .home-band h2 {
    font-weight: 300; }
    @media (max-width: 576px) {
      .ltt .home-band h2 {
        text-align: left;
        padding-left: 2rem;
        padding-right: 1rem; } }
  .ltt .home-band p {
    margin: 2rem auto;
    line-height: 2.2rem;
    font-size: 1.6rem; }
    @media (max-width: 576px) {
      .ltt .home-band p {
        text-align: left;
        padding-left: 2rem;
        padding-right: 1rem; } }
  .ltt .home-band.back-dark {
    background: #151919; }
    .ltt .home-band.back-dark h1, .ltt .home-band.back-dark h2 {
      color: #e2f3f7; }
    .ltt .home-band.back-dark p {
      color: #6d6e70;
      font-weight: 400; }
  .ltt .home-band.back-light {
    background: #e2f3f7; }
    .ltt .home-band.back-light h2 {
      color: #1a2232; }
    .ltt .home-band.back-light p {
      color: #6d6e70; }
  .ltt .home-band .btn-dark {
    background: #151919;
    border: 1px solid #151919;
    min-width: 16rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 2rem; }
    .ltt .home-band .btn-dark:hover {
      background: transparent;
      color: #151919; }
  .ltt .home-band .btn-soft {
    background: #e2f3f7;
    border: 1px solid #e2f3f7;
    min-width: 16rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 2rem;
    color: #151919; }
    .ltt .home-band .btn-soft:hover {
      background: transparent;
      color: #e2f3f7; }

.ltt .feature-band {
  padding: 2rem 0;
  text-align: center; }
  .ltt .feature-band .card-deck {
    padding: 3rem;
    width: 100%; }
  .ltt .feature-band .card {
    display: flex;
    border: none;
    margin-bottom: 5rem;
    min-width: 320px; }
    .ltt .feature-band .card .card-top i {
      background: #e2f3f7;
      border-radius: 50%;
      padding: 1rem; }
    .ltt .feature-band .card h2 {
      font-weight: 300;
      text-transform: uppercase;
      font-size: 1.6rem;
      margin-top: 3rem; }
    .ltt .feature-band .card p {
      text-align: left;
      font-size: 1.8rem;
      font-weight: 300;
      line-height: 2.5rem;
      padding: 0 2rem; }
    .ltt .feature-band .card i {
      font-size: 6rem; }

.ltt .icon-img {
  display: block;
  margin: 0 auto 1rem auto; }

.ltt .footer {
  padding-top: 3rem;
  padding-bottom: 1rem; }
  .ltt .footer .social {
    text-align: center; }
    .ltt .footer .social span {
      text-transform: uppercase; }
    .ltt .footer .social .share-icons {
      padding: 2rem;
      font-size: 2.5rem;
      color: #6d6e70; }
      .ltt .footer .social .share-icons i {
        margin: 0 2rem; }
      .ltt .footer .social .share-icons a {
        color: #6d6e70; }
        .ltt .footer .social .share-icons a:hover {
          text-decoration: none;
          color: #9a9a9a; }

@media only screen and (min-width: 976px) {
  .ltt .navbar .collapse.navbar-collapse.show {
    position: relative;
    right: 0;
    top: 0;
    background: transparent;
    opacity: 1;
    -webkit-transition: all .125s linear;
    transition: all .125s linear;
    padding: 0;
    filter: none; }
    .ltt .navbar .collapse.navbar-collapse.show .nav-link {
      color: #e2f3f7; } }

@media (max-width: 576px) {
  .ltt .home-band h2, .ltt .home-band p {
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem; }
  .ltt .home-band .text-left {
    text-align: center !important; } }
